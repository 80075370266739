import * as React from "react"
import {useState, useEffect} from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Banner from "../components/banner"
import { Pagi } from "../components/functions"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes
  //Next 3 concern pagination
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [showposts, setPosts] = useState(posts.slice(0,itemsPerPage));

  //Pagination...
  useEffect(() => {
    const start = (page-1) * itemsPerPage;
    const thispageposts = posts.slice(start, start + itemsPerPage)
    //I need to force the buttons to update here....
    setPosts(thispageposts)
    //eslint-disable-next-line
  }, [page,itemsPerPage])

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo title="All posts" />
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="All posts" />
      <Banner />
      <ol className="main-page">
        {showposts.map(post => {
          const title = post.frontmatter.title || post.fields.slug
          const totalscorearray = post.frontmatter.score.split(',');
          let bgcolor = totalscorearray[0] === "N/A" ? "neutral" : totalscorearray[0] < data.site.siteMetadata.cutoffScore ? "good" : "bad"

          return (
            <Link to={post.fields.slug} itemProp="url" key={post.fields.slug + "link"}>
              <div className="wrapper">
              {totalscorearray[0] !== "N/A" && <div className="scorebadgetop"><div className={`scorebadge`}>{totalscorearray[0]}</div></div>}
              <li className={`main-page ${bgcolor}`} key={post.fields.slug}>
              <article
                className="post-list-item"
                itemScope
                itemType="http://schema.org/Article"
              >
                <header>
                  <span className="mp-title-flex">
                  <h2>
                      <span itemProp="headline">{title}</span>
                  </h2>
                  <p className="mp-date">{post.frontmatter.date}</p>
                  </span>
                </header>
                <section className="mp-slug">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: post.excerpt,
                    }}
                    itemProp="description"
                  />
                </section>
              </article>
            </li>
            </div>
            </Link>
          )
        })}
      </ol>
      <Pagi setPerPage={setItemsPerPage} itemspp={itemsPerPage} setP={setPage} currentP={page} totalP={Math.ceil(posts.length / itemsPerPage)}/>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        cutoffScore
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { ne: "draft" } } }
      ) {
      nodes {
        excerpt(pruneLength: 70)
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          score
        }
      }
    }
  }
`
