/**
 * Banner image
 * Ideally banner images should be 664x100, in the images dir with a banner* filename.
 */

import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

const Banner = () => {

   const data = useStaticQuery(graphql`
     query BannerQuery {
      allFile(filter: {name: {regex: "/banner*/"}, relativeDirectory: {eq: "banners"}}) {
        edges {
          node {
            id
            name
            publicURL
            relativePath
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        totalCount
      }
     }
   `)
 
   // Whatever banner* files are in the images dir will get counted into here
   let randomBannerNumber = Math.floor(Math.random() * data.allFile.totalCount)
   const bannerImage = getImage(data.allFile.edges[randomBannerNumber].node.childImageSharp)
   const bannerAlt = "Home Page " + data.allFile.edges[randomBannerNumber].node.name

   return (
    <GatsbyImage
    image={bannerImage}
    className="banner-img"
    layout="constrained"
    formats={["auto", "webp", "avif"]}
    height={100}
    quality={50}
    alt={bannerAlt}
    />
   )
 }

 
export default Banner